import styles from './HeaderOneLine.module.scss';
import Item from '../Item';
export default function HeaderOneLine({ items }) {

  return (
    <>
      <div className={styles.container}>
        {items.map((item) => (
          <div key={item.uid} className={styles.item}>
            <Item item={item} />
          </div>
        ))}
      </div>
    </>
  );
}

