import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from 'react-i18next';

import Headers from './Headers'
export default function Blocks({ blocks }) {
  const { t } = useTranslation();

  return (
    <>
      <Tabs
        defaultActiveKey={blocks[0]?.uid}
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        {blocks.map((block) => {
          return <Tab key={block.uid} eventKey={block.uid} title={block.displayName} disabled={block.blockType !== 10}>
            <Headers headers={block.headers} />
          </Tab>
        })}
               
      </Tabs>
    </>
  );
}

