import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap'; 
import { useParams, useNavigate } from 'react-router-dom';
import styles from './Report.module.scss';
import api from "../../services/api";
import Loading from '../Shared/Loading'
import { useTranslation } from 'react-i18next';

import TemplateSelector from './TemplateSelector';
import Blocks from './Blocks';
import { useToastContext } from "../../toasts/toast-context";

export default function Report({ reportUrl, completeReportRedirectUrl, reportLoadCompleteCallback }) {
  let { consultationUid } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const protocolRef = useRef(null);
  const [templateUid, setTemplateUid] = useState(null);
  const [templateList, setTemplateList] = useState([]);  
  const { showSuccessToastMessage, showErrorToastMessage } = useToastContext();

  const reportSaved = useRef(false);
  let navigate = useNavigate();

  useEffect(() => {
    const loadReport = () => {
      api
        .get(reportUrl)
        .then((response) => {
          console.log(response.data);
          setReport(response.data.blocks);
          setIsReadOnly(response.data.isReadOnly);
          setTemplateList(response.data.templateList);
          setTemplateUid(response.data.selectedTemplateUid);
          setLoading(false);
          if (reportLoadCompleteCallback) reportLoadCompleteCallback();
        }, (error) => {
          setLoading(false);
        });
    }    

    loadReport();
   
  }, [consultationUid]);

  useEffect(() => {
    if (report === null)
      return;

    protocolRef.current = report;
    return () => {      
      if (!reportSaved.current)
        saveReport();
    };
  }, [report]);

  const onSaveSendButtonClick = () => {
    saveReport(true);
    reportSaved.current = true;
  };

  const saveReport = (markCompleted) => {
    console.log("Saving report...");
    let items = [];
    let report = protocolRef.current;

    if (report.length > 0) {
      report.forEach(block => {
        block.headers.forEach(header => {
          header.items.forEach(item => {
            if (!item.isReadOnly) {
              items.push({ uid: item.uid, value: { text: item.textValue, optionUid: item.options?.selectedOption } });
            }
          });       
        });
      });
    }    

    if (items.length > 0) {
      api
        .post(`${reportUrl}/save`, {
          items: items,
          isReportCompleted: markCompleted
        }).then((response) => {
          showSuccessToastMessage(t('consultation.saving.success'));
          if (markCompleted) {
            navigate(`${completeReportRedirectUrl}`);
          };
        }, (error) => {
          showErrorToastMessage(t('consultation.saving.error'));
        });
    }
  };

  const onTemplateChanged = (templateUid) => {    
    api
      .post(`${reportUrl}/change`, {
        templateUid: templateUid,
      })
      .then((response) => {
        console.log(response.data);
        setReport(response.data.blocks);        
      }, (error) => {        
      });
  };

  if (loading)
    return <Loading />;

  return (
    <>
      <div className={styles.fullScreenForm}>
        <div className={styles.innerScroll}>
          <div className={styles.inner}>
            {!isReadOnly &&
              <div className={styles.selector}>
                <TemplateSelector templateList={templateList} activeTemplateUid={templateUid} onTemplateChangedCallback={onTemplateChanged} />
                {!isReadOnly && <Button variant="primary" className={styles.stickyButton} onClick={onSaveSendButtonClick}>{t('consultation.expert.addReport.saveAndSend')}</Button>}
              </div>
            }
            <div className={styles.tabs}>
              <Blocks blocks={report} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
