import Form from 'react-bootstrap/Form';

export default function ItemTextArea({ item }) {

  const style = {
    h: {
      height: item.layout?.heightInRem === undefined ? 'auto' : item.layout?.heightInRem + 'rem'
    }
  };

  const onChange = (e) => {
    item.textValue = e.target.value;    
  };

  return (
    <>
      <Form.Group className="mb-3" controlId={item.Uid}>
        <Form.Label>{item.displayName}</Form.Label>
        <Form.Control as={"textarea"} style={style.h} defaultValue={item.textValue} onChange={onChange} disabled={item.isReadOnly}></Form.Control>
      </Form.Group>
    </>
  );
}