import Form from 'react-bootstrap/Form';
import { Container, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function TemplateSelector({ templateList, activeTemplateUid, onTemplateChangedCallback }) {  
  const { t } = useTranslation();

  const onSelectionChanged = (e) => {    
    if (onTemplateChangedCallback)
      onTemplateChangedCallback(e.target.value);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={6} md={8} xs={11} xsm>
            <Form.Label htmlFor="templateSelect" className="ms-1">{t('consultation.protocol.chooseTemplate')}</Form.Label>
            <Form.Select id="templateSelect" className="ms-1" defaultValue={activeTemplateUid} onChange={onSelectionChanged}>
              {templateList.map((x) => {
                return <option value={x.uid} key={x.uid}>{x.displayName}</option>
              }) }
            </Form.Select>
          </Col>
        </Row>
      </Container>
    </>
  );
}

