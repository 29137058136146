import styles from './HeaderTwoColumns.module.scss';
import Item from '../Item';
export default function HeaderTwoColumns({ items }) {
  const column0Items = items.filter(item => item.layout.columnId === 0);
  const column1Items = items.filter(item => item.layout.columnId === 1);

  return (
    <>
      <div className={styles.gridContainer}>
        <div className={styles.column}>
          {column0Items.map((item) => (
            <div key={item.uid}>
              <Item item={item} />
            </div>
          ))}
        </div>
        <div className={styles.column}>
          {column1Items.map((item) => (
            <div key={item.uid}>
              <Item item={item} />              
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

