import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Alert } from 'react-bootstrap';
import api from "../../../services/api";
import styles from './ViewConsultationList.module.scss';

import RequestConsultationConfirmModal from '../../Consultation/RequestConsultationConfirmModal';
import Loading from '../Loading';
import { dateFormatter } from '../../../utils/dateFormatter';
import OpenReportButton from '../../../components/ConsultationProtocol/OpenReportButton';

export default function ViewConsultationList({ recordUid, allowRequestConsultation }) {
  const { t } = useTranslation();
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [reportList, setReportList] = useState();

  const refreshList = () => {
    api
      .get(`/consultation/user/record/${recordUid}`)
      .then((response) => {
        setReportList(response.data);
        setInitialLoaded(true);
      });
  };

  useEffect(() => {
    refreshList(recordUid, allowRequestConsultation);
  }, [recordUid, allowRequestConsultation]);

  if (!initialLoaded) return <Loading />;

  return (
    <>
      <h2 className="h2">{t('consultation.user.view.header')}</h2>
      {reportList.items.length === 0 && (
        <>
          <h4 className="h4">{t('consultation.user.view.noConsultations')}</h4>
          {allowRequestConsultation && (
            <RequestConsultationConfirmModal
              recordUid={recordUid}
              requestSentCallback={refreshList}
            />
          )}
        </>
      )}
      {reportList.items.length > 0 && reportList.items.every(report => report.acceptDate && report.completeDate) && (
        <>
          {allowRequestConsultation && (
            <RequestConsultationConfirmModal
              recordUid={recordUid}
              requestSentCallback={refreshList}
            />
          )}
        </>
      )}
      {reportList.items.map(report => (
        <React.Fragment key={report.uid}>
          <div className={styles.consultationGrid}>
            <div className={styles.consultationColumn}>
              <div>{t('consultation.user.view.state')}: {report.state}</div>
              <div>{t('consultation.user.view.requestDate')}: {dateFormatter(report.requestDate)}</div>
              <div></div>
              <div>
                {!(report.acceptDate) && <i>{t('consultation.user.view.waitingExpert')}</i>}
                {(report.acceptDate && !report.completeDate) && <i>{t('consultation.user.view.waitingReport')}</i>}
              </div>
            </div>
            <div className={styles.consultationColumn}>
              <div>
                {(report.acceptDate) && (
                  <>{t('consultation.user.view.expertName')}: {report.expert.displayName}</>
                )}
              </div>
              <div>
                {report.acceptDate && (
                  <>{t('consultation.user.view.acceptDate')}: {dateFormatter(report.acceptDate)}</>
                )}
              </div>
              <div>
                {(report.acceptDate && report.completeDate) && (
                  <>{t('consultation.user.view.completeDate')}: {dateFormatter(report.completeDate)}</>
                )}
              </div>
            </div>
            <div className={styles.consultationColumn}>
              <div>
                {(report.acceptDate && report.completeDate) && (
                  <OpenReportButton locationType="client" clientReportUid={report.uid} />
                )}
              </div>
            </div>
          </div>
          <div className="mb-4"></div>
        </React.Fragment>
      ))}
    </>
  );
}