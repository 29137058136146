import { Form } from 'react-bootstrap'
import { useState } from 'react';
import styles from './ItemOptions.module.scss';

export default function ItemOptions({ item }) {
  const [selectedOption, setSelectedOption] = useState(item.options.selectedOption);

  const handleChange = (e) => {
    setSelectedOption(e.target.id);
    item.options.selectedOption = e.target.id;
  };

  const selectedOptionText = item.options.optionList.find(x => x.uid === item.options.selectedOption)?.displayName || '';

  return (
    <>
      {!item.isReadOnly &&
        <>
          <b>{item.displayName}:</b>
          <div key={`default-radio`} className="mb-3">
            {item.options.optionList.map(option => (
              <Form.Check
                className={styles.redRadio}
                type='radio'
                name={item.uid}
                id={option.uid}
                label={option.displayName}
                checked={selectedOption === option.uid}
                onChange={handleChange}
              />
            ))}
          </div>
        </>
      }
      {item.isReadOnly &&
        <>
          <b>{item.displayName}:</b> {selectedOptionText}
        </>
      }
    </>
  );
}
