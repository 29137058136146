import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import styles from '../SideMenu.module.scss'

import * as Icons from '../SideMenuIcons';
import { useTranslation } from 'react-i18next';
import { MenuList } from './MenuList';

export { MenuListSettings };
function MenuListSettings({ closeOffcanvasAction }) {
  
  const location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();

  useEffect(() => {
  });


  const settingsLinkList = [    
    {
      link: `/settings/profile`,
      text: t('sidebar.settings.profile'),
      icon: <Icons.Profile />,
      isActive: function () { return path.includes(this.link); }      
    },
    {
      link: `/settings/notifications`,
      text: t('sidebar.settings.notifications'),
      icon: <Icons.Bell />,
      isActive: function () { return path.includes(this.link); }
    },
    {
      link: `/settings/my-users`,
      text: t('sidebar.settings.myUsers'),
      icon: <Icons.TwoPeople />,
      isActive: function () { return path.includes(this.link); }
    }
  ];

  return (
    <>
      <div className={`${styles.header} ${styles.first}`}>
        <Icons.Shield />
        {t('sidebar.settings.header')}
      </div>
      <MenuList itemsList={settingsLinkList} closeOffcanvasAction={closeOffcanvasAction} />
    </>

  );

}