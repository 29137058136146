import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//import styles from './ItemNumber.module.scss';

export default function ItemNumber({ item }) {

  const style = {
    h: {
      height: item.layout?.heightInRem === undefined ? 'auto' : item.layout?.heightInRem + 'rem'
    }
  };

  const onChange = (e) => {
    item.textValue = e.target.value;    
  };

  return (
    <>
      {!item.isReadOnly &&
        <Form.Group className="mb-3" controlId={item.Uid} as={Row}>
          <Form.Label column sm="4" >{item.displayName}</Form.Label>          
          <Col sm="4">
            <Form.Control as={"input"} style={style.h} defaultValue={item.textValue} onChange={onChange} disabled={item.isReadOnly}></Form.Control>          
          </Col>
        </Form.Group>
      }
      {item.isReadOnly &&
        <>
          <b>{item.displayName}:</b> {item.textValue}
        </>
      }
    </>
  );
}