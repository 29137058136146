import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import { useTranslation } from 'react-i18next';
import { Button, Alert } from 'react-bootstrap';
import { dateFormatter } from '../../utils/dateFormatter'
import { useNavigate } from 'react-router-dom';

import Loading from '../Shared/Loading'

import { FormEditLine, SeparateRowForButtonOnly } from '../Shared/CommonForm/CommonForm';
import Form from 'react-bootstrap/Form';
import { useToastContext } from "../../toasts/toast-context";
import { Formik } from "formik";
import * as Yup from "yup";


export default function DeviceEdit({ getDeviceEditUrl, baseUrl, isNew }) {
  const { t } = useTranslation();
  let navigate = useNavigate();  

  const [loading, setLoading] = useState(!isNew);

  const [receivedData, setReceivedData] = useState({ deviceModelType: '10'});
  const { showSuccessToastMessage, showErrorToastMessage } = useToastContext();
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    function loadData() {
      api
        .get(`${getDeviceEditUrl}`)
        .then((response) => {          
          response.data.deviceModelType = String(response.data.deviceModelType);
          setReceivedData(response.data);

          setLoading(false);
        }, (error) => {
          setLoading(false);
        });
    };

    if (!isNew)
      loadData();

  }, [getDeviceEditUrl, baseUrl, isNew]);

  const handleSubmit = async (data, form) => {
    setIsSending(true);
    setMessage("");

    api
      .post(`${getDeviceEditUrl}`, data, { formik: form, setErrorMessage: setMessage })
      .then((response) => {
        showSuccessToastMessage(t('metrology.edit.messages.success'));
        navigate(-1);
      },
        (error) => {
          setLoading(false);
          setIsSending(false);
          showErrorToastMessage(t('metrology.edit.messages.error'));
          });    
  }

  if (loading)
    return <Loading />;

  const setDeviceType = (values) =>{
    values.deviceType = '2';
  };

  return (
    <>
      <Formik
        initialValues={{
          uid: receivedData.uid,
          bluetoothAddress: receivedData.bluetoothAddress,
          serialNumber: receivedData.serialNumber,          
          deviceModelType: receivedData.deviceModelType
        }}
        validationSchema={Yup.object({
          bluetoothAddress: Yup.string()
            .required(t("validation.required"))
            .max(100, t("validation.maxLength100")),
          serialNumber: Yup.string()
            .required(t("validation.required"))
            .max(100, t("validation.maxLength100")),
          deviceModelType: Yup.string()
        })}
        onSubmit={handleSubmit}>
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>           
            <FormEditLine xxlWide displayName={t('metrology.deviceInfo.bluetoothAddress')}>
              <Form.Group controlId="validationFormik01">
                <Form.Control
                  type="text"
                  name="bluetoothAddress"
                  value={values.bluetoothAddress}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.bluetoothAddress && !!errors.bluetoothAddress}
                  disabled={!isNew}
                />
                <Form.Control.Feedback type="invalid" className="mb-4">{errors.bluetoothAddress}</Form.Control.Feedback>
              </Form.Group>
            </FormEditLine>   

            <FormEditLine xxlWide displayName={t('metrology.deviceInfo.serialNumber')}>
              <Form.Group controlId="validationFormik02">
                <Form.Control
                  type="text"
                  name="serialNumber"
                  value={values.serialNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.serialNumber && !!errors.serialNumber}                  
                />
                <Form.Control.Feedback type="invalid" className="mb-4">{errors.serialNumber}</Form.Control.Feedback>
              </Form.Group>
            </FormEditLine>                      

            <FormEditLine xxlWide displayName={t('metrology.deviceInfo.modelName')}>
              <Form.Group controlId="validationFormik04">                
                <Form.Select name="deviceModelType" onChange={(e) => { handleChange(e); setDeviceType(values); }} value={values.deviceModelType}>
                  <option value={'0'} key={'0'}>{t('metrology.edit.notSelected')}</option>
                  <option disabled="disabled">--------------</option>
                  <option value={'10'} key={'10'}>{`CardioAssistant Home`}</option>
                  <option value={'11'} key={'11'}>{`CardioAssistant Pro`}</option>
                  <option value={'12'} key={'12'}>{`CardioAssistant Lite`}</option>
                  <option disabled="disabled">--------------</option>
                  <option value={'21'} key={'21'}>{`CardioAssistant Comfort`}</option>
                  <option value={'22'} key={'22'}>{`CardioAssistant Comfort+`}</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid" className="mb-4">{errors.deviceModelType}</Form.Control.Feedback>
              </Form.Group>
            </FormEditLine>   

            <SeparateRowForButtonOnly>
              <Button variant="outline-primary" className="me-3" disabled={isSending} onClick={() => navigate(-1)}>
                <span> {t('common.cancelButton')}</span>
              </Button>

              <Button variant="primary" className="" type="submit" disabled={isSending}>
                {isSending && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span> {t('common.saveChanges')}</span>
              </Button>
            </SeparateRowForButtonOnly>

            {message && (
              <Alert key="danger" variant="danger" className="mt-3">
                {message}
              </Alert>
            )}
          </Form>
        )}

      </Formik>     
      
    </>
  );
}

