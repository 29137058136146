import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import UserDevices from '../../../components/Shared/Devices/UserDevices'
import OpenReportButton from '../../../components/ConsultationProtocol/OpenReportButton'

import MainBlockContainer from '../../MainBlockContainer';
import MainBlockHeader from '../../MainBlockHeader'
import { useTranslation } from 'react-i18next';

export default function Devices() {
  let { consultationUid } = useParams();
  const { t } = useTranslation();

  //useEffect(() => {   

  //}, []);


  return (
    <>
      <MainBlockHeader header={t('devices.header')} />

      <MainBlockContainer>
        <UserDevices apiDevicesBaseUrl={`/consultation/expert/accepted/${consultationUid}/user/devices`} />
        <OpenReportButton locationType="accepted" />
      </MainBlockContainer>
    </>
  );
}

