import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import HeaderOneLine from './Headers/HeaderOneLine'
import HeaderTwoColumns from './Headers/HeaderTwoColumns'
import HeaderTable from './Headers/HeaderTable'
import styles from './Headers.module.scss'

export default function Headers({ headers }) {

  return (
    <>
      {headers.map((header) => {
        return <div key={header.uid} className={styles.mainHeader}>
          {header.displayName !== '' && <h3>{header.displayName}</h3> }
          {header.layoutType === 10 && <HeaderOneLine items={header.items} />}
          {header.layoutType === 20 && <HeaderTwoColumns items={header.items} />}
          {header.layoutType === 30 && <HeaderTable items={header.items} />}
        </div>
        })
      }
    </>
  );
}

