import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

//import UserViewRecords from '../../components/Shared/RecordsList/UserViewRecords'
import UserViewOneRecord from '../../../components/Shared/User/UserViewOneRecord'
//import AddReport from '../../../components/Consultation/AddReport';

import MainBlockHeader from '../../MainBlockHeader'
import MainBlockContainer from '../../MainBlockContainer';
import { useTranslation } from 'react-i18next';
import OpenReportButton from '../../../components/ConsultationProtocol/OpenReportButton'

export default function RecordViewConsultation() {  
  let { consultationUid, recordUid } = useParams();
  const { t } = useTranslation();

  return (
    <>              
      <MainBlockHeader header={t('recordList.header')} >
      </MainBlockHeader>

      <MainBlockContainer>      
        <UserViewOneRecord
          userUid={null}
          recordUid={recordUid}          
          baseOneRecordUrl={ `/consultation/expert/completed/${consultationUid}/user/record` }
          allowRequestConsultation={false}          
        />

        {/*<AddReport consultationUid={consultationUid} baseUrl={`/consultation/expert/completed`} listUrl={`/consultations/expert/completed/list`} readOnly={true} />*/}
        <OpenReportButton locationType="completed" />
      </MainBlockContainer>
    </>
  );  
}

