import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap'; 
import { useParams } from 'react-router-dom';
import styles from './OpenReportButton.module.scss';
import Report from './Report';

import { useTranslation } from 'react-i18next';

export default function OpenReportButton({ locationType, clientReportUid }) {
  let { consultationUid } = useParams();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [reportOpened, setReportOpened] = useState(false);

  const reportUrl = (() => {
    switch (locationType) {
      case 'accepted':
        return `/consultation/expert/accepted/${consultationUid}/report`;
      case 'completed':
        return `/consultation/expert/completed/${consultationUid}/report`;
      case 'client':
        return `/consultation/user/report/${clientReportUid}`;
      default:
        return ``;
    }
  })();
  const completeReportRedirectUrl = locationType === 'accepted' ? '/consultations/expert/accepted/list' : '';
  const btnDefaultText = locationType === 'client' ? t('consultation.button.view') : t('consultation.button.open');
  const btnStyle = (locationType === 'client' && !visible) ? styles.regular : styles.floating;

  const openReportClick = () => {
    if (!visible) {
      setReportLoading(true);
      setVisible(true);
    }
    else {
      setVisible(false);
      setReportOpened(false);
    }
  };

  const reportLoadComplete = () => {
    setReportLoading(false);
    setReportOpened(true);
  };

  return (
    <>
      <Button variant="primary" className={btnStyle} onClick={openReportClick}>
        {reportLoading && <Spinner animation="border" variant="warning" className={"me-2"} />} {reportOpened ? t('consultation.button.close') : btnDefaultText}
      </Button>
      {visible && <Report reportUrl={reportUrl} completeReportRedirectUrl={completeReportRedirectUrl} reportLoadCompleteCallback={reportLoadComplete} />}
    </>
  );
}

