import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function ItemText({ item }) {

  const onChange = (e) => {
    item.textValue = e.target.value;    
  };

  return (
    <>
      <Form.Group className="mb-3" controlId={item.Uid} as={Row}>
        <Form.Label column sm="4" >{item.displayName}</Form.Label>
        <Col sm="4">
          <Form.Control as={item.answerType === 10 ? "input" : "textarea"}  defaultValue={item.textValue} onChange={onChange} disabled={item.isReadOnly}></Form.Control>
        </Col>
      </Form.Group>
    </>
  );
}