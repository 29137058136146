import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

//import UserViewRecords from '../../components/Shared/RecordsList/UserViewRecords'
import UserViewOneRecord from '../../../components/Shared/User/UserViewOneRecord'
//import AddReport from '../../../components/Consultation/AddReport';
import OpenReportButton from '../../../components/ConsultationProtocol/OpenReportButton'

import MainBlockHeader from '../../MainBlockHeader'
import MainBlockContainer from '../../MainBlockContainer';
import { useTranslation } from 'react-i18next';

export default function RecordViewConsultation() {  
  let { consultationUid, recordUid } = useParams();
  const { t } = useTranslation();

  return (
    <>              
      <MainBlockHeader header={t('recordList.header')} >
      </MainBlockHeader>

      <MainBlockContainer>      
        <UserViewOneRecord
          userUid={null}
          recordUid={recordUid}          
          baseOneRecordUrl={ `/consultation/expert/accepted/${consultationUid}/user/record` }
          allowRequestConsultation={false}
        />

        {/*<AddReport consultationUid={consultationUid} baseUrl={`/consultation/expert/accepted`} listUrl={`/consultations/expert/accepted/list`} readOnly={false} />*/}
        <OpenReportButton locationType="accepted" />
      </MainBlockContainer>
    </>
  );  
}

