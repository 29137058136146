
import ItemLabel from './Items/ItemLabel';
import ItemText from './Items/ItemText';
import ItemTextArea from './Items/ItemTextArea';

import ItemNumber from './Items/ItemNumber';
import ItemOptions from './Items/ItemOptions';
import ItemImage from './Items/ItemImage';

export default function Item({ item }) {
  return (
    <>
      <div>
        {item.isReadOnly && item.answerType === 10 && <ItemLabel item={item} />}
        {!item.isReadOnly && item.answerType === 10 && <ItemText item={item} />}

        {item.answerType === 11 && <ItemTextArea item={item} />}
        
        {item.answerType === 20 && <ItemNumber item={item} />}

        {item.answerType === 30 && <ItemOptions item={item} />}
        {item.answerType === 40 && <ItemImage item={item} />}
      </div>
    </>
  );
}

